import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = ({children, ...props}) => (
  <div className="island text-center">
    <CircularProgress size={80} thickness={5} {...props} />
    {children ? <div className="text--center">{children}</div> : null}
  </div>
);
Loader.displayName = 'Loader';
export default Loader;

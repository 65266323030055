import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Header from './routes/layout/Header';
import Layout from './routes/Layout';
import AboutBusiness from './routes/AboutBusiness';
import FundingRequirements from './routes/FundingRequirements';
import Trade from './routes/Trade';
import Funder from './routes/Funder';
import Success from './routes/Success';
//import GoogleAnalyticsTracker from './routes/GoogleAnalyticsTracker';

import {steps} from './constants';
import routes from './routes';

class AppRoutes extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Route />

        <Route component={Header} />

        <Switch>
          <Route
            exact={true}
            path={routes.aboutBusiness}
            render={props => (
              <Layout>
                <AboutBusiness {...props} step={steps[0]} />
              </Layout>
            )}
          />

          <Route
            exact={true}
            path={routes.fundingRequirements}
            render={props => (
              <Layout>
                <FundingRequirements {...props} step={steps[1]} />
              </Layout>
            )}
          />

          <Route
            exact={true}
            path={routes.trade}
            render={props => (
              <Layout>
                <Trade {...props} step={steps[2]} />
              </Layout>
            )}
          />

          <Route
            exact={true}
            path={routes.funder}
            render={props => (
              <Layout>
                <Funder {...props} step={steps[3]} />
              </Layout>
            )}
          />

          <Route
            exact={true}
            path={routes.success}
            render={props => <Success {...props} layout={{showContent: false}} />}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default AppRoutes;

import PropTypes from 'prop-types';
import React from 'react';
import MaterialRadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import wrapField from './Field';

const styles = {
  display: 'inline-block',
  paddingLeft: '1em',
  width: null,
};

const RadioGroup = props => (
  <div>
    <label className="radio-group-label">{props.label}</label>

    <MaterialRadioGroup {...props} valueSelected={props.value}>
      {props.options.map((o, i) => (
        <FormControlLabel key={i} style={styles} value={o.value} label={o.text} />
      ))}
    </MaterialRadioGroup>
  </div>
);

RadioGroup.displayName = 'RadioGroup';
RadioGroup.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
};

export default wrapField(RadioGroup);

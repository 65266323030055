import PropTypes from 'prop-types';
import React from 'react';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import isArray from 'isarray';
import wrapField from './Field';

const TickBox = props => {
  const handleChange = option => ev => {
    const wasChecked = ev.target.value;
    const values = isArray(props.value) ? props.value : [];
    const newValues = wasChecked
      ? values.filter(v => v !== option.value)
      : values.concat(option.value);

    if (props.onChange) props.onChange(newValues);
  };

  return (
    <div>
      <label className="radio-group-label">{props.label}</label>
      <div className="tickbox">
        {props.options.map((o, i) => (
          <FormControlLabel
            label={o.text}
            control={
              <MaterialCheckbox
                checked={isArray(props.value) && props.value.indexOf(o.value) > -1}
                key={i}
                onChange={handleChange(o)}
                value={o.value}
              />
            }
          />
        ))}
      </div>
    </div>
  );
};

TickBox.displayName = 'TickBox';
TickBox.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
};

export default wrapField(TickBox);

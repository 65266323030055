import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import {redA100 as alertRed} from '@material-ui/core/colors';
import isArray from 'isarray';

const errorStyles = {
  position: 'relative',
  bottom: '15px',
  fontSize: '12px',
  lineHeight: '12px',
  color: 'rgb(244, 67, 54)',
  transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
};

class FunderList extends React.Component {
  static propTypes = {
    funders: PropTypes.array.isRequired,
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.any,
    }).isRequired,
    meta: PropTypes.object.isRequired,
  };

  handleCheck = funder => ev => {
    const {onChange, value} = this.props.input;
    const {checked} = ev.target;
    const newValues = isArray(value) ? value : [];

    if (checked && newValues.indexOf(funder.id) > -1) {
      onChange(newValues);
      return;
    }

    if (onChange) {
      onChange(checked ? newValues.concat(funder.id) : newValues.filter(v => v !== funder.id));
    }
  };

  render() {
    const {input, funders, meta} = this.props;
    const {value} = input;

    return (
      <div className="funder-list">
        <label>Select funders</label>
        {funders.length === 0 ? (
          <Paper style={{backgroundColor: alertRed}}>
            <div className="soft push--bottom">
              Unfortunately, we could not find any matching funders. Please leave your details below
              and we'll be in touch.
            </div>
          </Paper>
        ) : (
          <div>
            <hr />
            {funders.map((f, i) => (
              <div key={i} className="funder-list-item">
                <div className="row">
                  <div className="column">
                    <span className="funder-list-item__logo">
                      <img src={f.logoUrl} alt={f.provider} />
                    </span>
                  </div>

                  <div className="column">
                    <span className="funder-list-item__product">{f.product}</span>
                  </div>

                  <div className="column">
                    <span className="funder-list-item__rating">
                      <span className="funder-list-item__rating-value">{f.rating}</span>
                      &nbsp;match
                    </span>
                  </div>

                  <div className="column">
                    <span className="funder-list-item__check">
                      <Checkbox
                        defaultChecked={isArray(value) && value.indexOf(f.id) > -1}
                        onChange={this.handleCheck(f)}
                      />
                    </span>
                  </div>
                </div>

                <hr />
              </div>
            ))}
          </div>
        )}

        {meta.error && meta.touched ? <div style={errorStyles}>{meta.error.message}</div> : null}
      </div>
    );
  }
}

export default FunderList;

import React from 'react';
import {Link} from 'react-router-dom';

const PrimaryMenu = () => (
  <nav>
    <ul className="menu align-right">
      <li>
        <Link to="/" className="active">
          get funding
        </Link>
      </li>
      <li>
        <Link to="https://fundinghub.co.za">home</Link>
      </li>
    </ul>
  </nav>
);

export default PrimaryMenu;

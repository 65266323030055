import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';

import createStore, {history} from './store';
import reducers from './reducers';

import AppRoutes from './AppRoutes';

const store = createStore(reducers);

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AppRoutes />
        </ConnectedRouter>
      </Provider>
    );
  }
}

App.displayName = 'App';

export default App;

import React from 'react';
import DatePicker from 'material-ui-pickers/DatePicker';

import wrapField from './Field';

class DateField extends React.Component {
  static displayName = 'DateField';

  render() {
    /* eslint-disable-next-line */
    const {format, value, visible, ...rest} = this.props;
    const normalisedValue = value ? value : null;

    return <DatePicker {...rest} value={normalisedValue} invalidLabel="" />;
  }
}

export default wrapField(DateField);

import exprEval from 'expr-eval';

const parser = new exprEval.Parser({operators: {logical: true}});

export default function evaluateVisible(field, fields, values = {}) {
  if (field.visible === false) {
    return false;
  }

  if (!field.condition) {
    return true;
  }

  const expressionVars = fields.reduce(
    (acc, f) => Object.assign(acc, {[f.name]: values[f.name] || ''}),
    {},
  );

  return parser.parse(field.condition).evaluate(expressionVars);
}

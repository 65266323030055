import routes from '../routes';

const steps = [
  {
    id: 1,
    name: 'aboutBusiness',
    label: 'About You and Your Business',
    route: routes.aboutBusiness,
  },
  {
    id: 2,
    name: 'fundingRequirements',
    label: 'Your Funding Requirements',
    route: routes.fundingRequirements,
  },
  {
    id: 3,
    name: 'trade',
    label: 'How You Trade',
    route: routes.trade,
  },
  {
    id: 4,
    name: 'funder',
    label: 'Choose a Funder',
    route: routes.funder,
  },
];

steps.findById = function findStepById(id) {
  return steps.find(s => s.id === id);
};

steps.findByName = function findStepByName(name) {
  return steps.find(s => s.name === name);
};

export default steps;

import PropTypes from 'prop-types';
import React from 'react';
import Button from '@material-ui/core/Button';
import Footer from './Footer';

const {location} = global;


function navToHome() {
  location.href = 'https://fundinghub.co.za';
}

class HeaderSuccess extends React.Component {
  static displayName = 'HeaderSuccess';
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <div className="header-text">
        <h1>Thank You!</h1>

        <p className="push--large--bottom">
          Your request has been sent to the selected funders and they will be in touch<br />
          with you shortly. You will also be receiving an email confirming your information<br />
          submitted and the details of the funders it has been sent to.
        </p>

        <Button variant="contained" onClick={navToHome}>
          HOME
        </Button>


        <Footer className="area-footer--alt" />
      </div>
    );
  }
}

export default HeaderSuccess;

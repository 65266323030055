import PropTypes from 'prop-types';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {watchDims} from '../utils';
import {steps} from '../constants';

class FundingSteps extends React.Component {
  static propTypes = {
    orientation: PropTypes.string,
    stepIndex: PropTypes.number,
  };

  render() {
    const {stepIndex, orientation} = this.props;

    return (
      <Paper>
        <div className="soft--sides">
          <Stepper activeStep={stepIndex || 0} orientation={orientation}>
            {steps.map(s => (
              <Step key={s.id}>
                <StepLabel>{s.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </Paper>
    );
  }
}

function mapResizeToProps({clientWidth}) {
  return {
    orientation: clientWidth > 782 ? 'horizontal' : 'vertical',
  };
}

export default watchDims(mapResizeToProps)(FundingSteps);

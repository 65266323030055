// export const STEPS_SET_STEP = '@steps/SET_STEP';
// export function setStep(id) {
//   return { type: STEPS_SET_STEP, id };
// }

export const STEPS_SET_STEP_DATA = '@steps/SET_STEP_DATA';
export function setStepData(id, data) {
  return {type: STEPS_SET_STEP_DATA, id, data};
}

export const STEPS_SET_STEP_RESULT = '@steps/SET_STEP_RESULT';
export function setStepResult(id, result) {
  return {type: STEPS_SET_STEP_RESULT, id, result};
}

export const STEPS_SET_ACTIVE = '@steps/SET_ACTIVE_STEP';
export function setActiveStep(stepId) {
  return {type: STEPS_SET_ACTIVE, stepId};
}

export const STEPS_RESET = '@steps/RESET';
export function resetSteps() {
  return {type: STEPS_RESET};
}

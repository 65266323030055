import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Field, reduxForm as connectForm, SubmissionError, stopSubmit} from 'redux-form';
import assign from 'object.assign';
import isArray from 'isarray';
import TagManager from 'react-gtm-module'
import {validateFields, extraneousValuifyObject} from '../utils';
import {fields as constantFields, steps as stepConstants} from '../constants';
import {steps as actions, api as apiActions} from '../actions';
import FunderList from '../components/FunderList';
import ErrorCard from '../components/ErrorCard';
import Loader from '../components/Loader';
import routes from '../routes';

const FORM_NAME = 'funder';
const loaderText = (
  <span>
    We are processing your request and finding the best funder for your requirements. Please don’t
    close the browser, this won’t take long.
  </span>
);

const permissionLabel = (
  <span>
    I/We consent and agree to a credit bureau check/s being performed on my business and/or any of
    the directors/members/principals.
  </span>
);

const tosLabel = (
  <span>
    I understand the{' '}
    <a href="https://www.fundinghub.co.za/terms" target="_blank">
      terms and conditions
    </a>{' '}
    and permit my info to be sent to these lenders.
  </span>
);

export class Funder extends Component {
  displayName = 'Funder';

  state = {tosChecked: false, permitChecked: false};

  static propTypes = {
    applicationId: PropTypes.string.isRequired,
    clearErrors: PropTypes.func,
    error: PropTypes.object,
    funders: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    postApi3: PropTypes.func.isRequired,
    pushLocation: PropTypes.func.isRequired,
    resetSteps: PropTypes.func.isRequired,
    setStepData: PropTypes.func.isRequired,
    step: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const {setActiveStep, step} = this.props;

    setActiveStep(step.id);
  }

  handleSubmit = values => {
    const {applicationId, postApi3, pushLocation, resetSteps, setStepData, step} = this.props;

    const currentStepId = step.id;
    setStepData(currentStepId, values);

    TagManager.initialize({gtmId: 'GTM-K6WSZMP',dataLayer: {event: 'fundingHubStep4'}})
    
    const payload = extraneousValuifyObject(
      assign({}, values, {
        Id: applicationId,
      }),
    );

    return postApi3(payload)
      .then(result => {
        if (result.result === 'OK') {
          pushLocation(routes.success);
          resetSteps();
        } else {
          this.handleError(result);
        }
      })
      .catch(err => {
        this.handleError(err);
      });
  };

  handleError = err => {
    setTimeout(() => this.scrollToElem(), 0);

    throw new SubmissionError({_error: err});
  };

  scrollToElem = () => {
    if (this.scrollElem) {
      window.scrollTo({top: this.scrollElem.offsetTop, left: 0, behaviour: 'smooth'});
    }
  };

  handleBackClick = () => {
    const {step, pushLocation} = this.props;

    pushLocation(stepConstants.findById(step.id - 1).route);
  };

  handleTOSChecked = ev => {
    this.setState({tosChecked: ev.target.checked});
  };

  handlePERMITChecked = ev => {
    this.setState({permitChecked: ev.target.checked});
  };

  render() {
    const {handleSubmit, funders, error, clearErrors, isBusy} = this.props;
    const {tosChecked, permitChecked} = this.state;

    return (
      <form onSubmit={handleSubmit(this.handleSubmit)} ref={c => (this.scrollElem = c)}>
        {error ? <ErrorCard error={error} onCloseClick={clearErrors} /> : null}

        {isBusy ? (
          <Loader text={loaderText} />
        ) : (
          <div>
            <Field name="FunderSel" component={FunderList} funders={funders} />

            <div className="push--large--bottom">
              <FormControlLabel
                label={tosLabel}
                control={<Checkbox name="agreeTOS" onChange={this.handleTOSChecked} />}
              />
            </div>

            <div className="push--large--bottom">
              <FormControlLabel
                label={permissionLabel}
                control={<Checkbox name="agreePERMIT" onChange={this.handlePERMITChecked} />}
              />
            </div>

            <Button
              disabled={isBusy || !tosChecked || !permitChecked}
              type="submit"
              variant="contained"
            >
              CONTINUE
            </Button>

            <Button variant="text" onClick={this.handleBackClick}>
              BACK
            </Button>
          </div>
        )}
      </form>
    );
  }
}

function mapStateToProps({application}) {
  const {steps, api} = application;
  const {results} = steps;
  const {applicationId} = results;
  const stepResponse = results.funder;
  const funders = stepResponse ? stepResponse.funders : [];

  return {
    funders,
    isBusy: api.three.isBusy,
    applicationId,
  };
}

const connectedComponent = connectForm({
  form: FORM_NAME,
  validate: validateFields({
    fields: constantFields.funder.concat({
      name: 'FunderSel',
      custom(value) {
        if (!isArray(value) || value.length === 0) {
          return {
            name: 'Funders',
            type: 'required',
            value,
            message: 'Please select at least one funder.',
          };
        }
      },
    }),
  }),
})(Funder);

export default connect(mapStateToProps, {
  pushLocation: push,
  clearErrors: () => stopSubmit(FORM_NAME, {}),
  ...actions,
  ...apiActions,
})(connectedComponent);

import PropTypes from 'prop-types';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

function getError(error) {
  if (error.errors) {
    // eslint-disable-next-line no-underscore-dangle
    return error.errors._error;
  }
  return error;
}

const ErrorCard = ({error, onCloseClick}) => {
  const err = getError(error);

  return (
    <Card style={{backgroundImage: 'linear-gradient(-35deg, #6edfbe, #3f5791)'}}>
      <CardHeader
        title="Dear Applicant..."
        subtitle={err.name}
        avatar={
          <div className="soft--small--ends">
            <img src="/assets/img/logo.svg" role="presentation" />
          </div>
        }
      />
      <CardContent style={{color: 'white'}}>{err.message || 'Unknown Error'}</CardContent>

      {onCloseClick ? (
        <CardActions>
          <Button onClick={onCloseClick} variant="text">
            Close
          </Button>
        </CardActions>
      ) : null}
    </Card>
  );
};

ErrorCard.propTypes = {
  error: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func,
};

export default ErrorCard;

import React from 'react';
import isArray from 'isarray';
import FormHelperText from '@material-ui/core/FormHelperText';

export function getErrorMessage(name, meta, props) {
  if (!meta.error || props.visible === false) {
    return undefined;
  }

  const {type: errorType, value, message} = meta.error;

  if (message) {
    return message;
  }

  switch (errorType) {
    case 'required':
      return `${name || 'This field'} is required.`;

    case 'email':
      return 'Please enter a valid email address.';

    case 'unique':
      return `'${value}' is already taken.`;

    case 'match':
      return `Must match ${name || 'Field'}.`;

    case 'maxLength':
      if (isArray(value)) {
        return `${name || 'Field'} must have at most ${meta.error.minLength} items.`;
      }
      return `${name || 'Field'} must be at most ${meta.error.minLength} characters long.`;

    case 'minLength':
      if (isArray(value)) {
        return `${name || 'Field'} must have at least ${meta.error.minLength} items.`;
      }
      return `${name || 'Field'} must be at least ${meta.error.minLength} characters long.`;

    default:
      return `${name || 'Field'} is invalid. Reason: ${meta.error.reason}`;
  }
}

function propsForInput({input, meta, ...rest}) {
  const errorText = meta.touched ? getErrorMessage(rest.displayName, meta, input) : null;

  return {
    ...input,
    ...rest,
    errorText,
    error: !!errorText,
  };
}

const Field = Component => {
  const wrap = props => {
    const {errorText, ...rest} = propsForInput(props);

    return props.visible ? (
      <div className="field" title={props.title}>
        <Component {...rest} />

        {errorText ? <FormHelperText error={rest.error}>{errorText}</FormHelperText> : null}
      </div>
    ) : (
      <div />
    );
  };

  wrap.displayName = `Field(${Component.displayName})`;

  return wrap;
};

export default Field;

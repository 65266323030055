import PropTypes from 'prop-types';
import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import MaterialSelectField from '@material-ui/core/Select';

import wrapField from './Field';

class SelectField extends React.Component {
  static displayName = 'SelectField';
  static propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
  };

  render() {
    /* eslint-disable-next-line */
    const {options, visible, ...rest} = this.props;

    return (
      <React.Fragment>
        <InputLabel htmlFor={rest.id || rest.name}>{rest.label}</InputLabel>

        <MaterialSelectField onChange={this.handleChange} {...rest}>
          {options.map(o => (
            <MenuItem key={`mi-${o.value}`} value={o.value}>
              {o.text}
            </MenuItem>
          ))}
        </MaterialSelectField>
      </React.Fragment>
    );
  }

  handleChange = (event, {props: {value}}) => {
    const {onChange} = this.props;

    onChange(value);
  };
}

export default wrapField(SelectField);

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

import Footer from './layout/Footer';

class Layout extends Component {
  displayName = 'Layout';

  static propTypes = {
    children: PropTypes.element,
  };

  componentDidUpdate(prevProps) {
    const {pathname: prevPathname} = prevProps.location;
    const {pathname} = this.props.location;

    if (prevPathname !== pathname) {
      this.scrollToContent();
    }
  }

  scrollToContent = () => {
    if (this.scrollElem) {
      window.scrollTo({top: this.scrollElem.offsetTop, left: 0, behaviour: 'smooth'});
    }
  };

  render() {
    const {children} = this.props;
    const showContent = true;

    return showContent ? (
      <React.Fragment>
        <div id="main" className="wrap area-content" ref={c => (this.scrollElem = c)}>
          <Paper>
            <div className="area-content--inner">{children}</div>
          </Paper>
        </div>

        <div className="footer-container">
          <Footer />
        </div>
      </React.Fragment>
    ) : null;
  }
}

export default withRouter(Layout);

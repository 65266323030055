import React from 'react';
import debounce from 'lodash/fp/debounce';

const {addEventListener, removeEventListener} = global;
const window = global;

let eventHandlers = [];

function getDims(element = window.document.body) {
  return {
    clientWidth: element.clientWidth,
    clientHeight: element.clientWidth,
  };
}

function resizeHandler() {
  const dims = getDims();
  eventHandlers.forEach(eh => eh(dims));
}

const debouncedResizeHandler = debounce(200, resizeHandler);

function startResizeListener(handler) {
  if (eventHandlers.length === 0) {
    addEventListener('resize', debouncedResizeHandler, false);
  }
  eventHandlers.push(handler);
}

function stopResizeListener(handler) {
  eventHandlers = eventHandlers.filter(e => e !== handler);

  if (eventHandlers.length === 0) {
    removeEventListener('resize', debouncedResizeHandler);
  }
}

export default function watchDims(mapResizeToProps) {
  return Component =>
    class WatchDims extends React.Component {
      displayName = `watchDims(${Component.displayName})`;

      state = {dims: getDims()};

      componentWillMount() {
        startResizeListener(this.handleResize);
      }

      componentWillUnmount() {
        stopResizeListener(this.handleResize);
      }

      getDimProps = () => {
        const {dims} = this.state;
        return mapResizeToProps ? mapResizeToProps(dims) : dims;
      };

      getWrappedInstance = () => {
        return this.wrappedInstance;
      };

      handleResize = dims => {
        this.setState({dims});
      };

      render() {
        const dimProps = this.getDimProps();
        return (
          <Component
            ref={r => {
              this.wrappedInstance = r;
            }}
            {...dimProps}
            {...this.props}
          />
        );
      }
    };
}

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import Button from '@material-ui/core/Button';
import {SubmissionError, stopSubmit} from 'redux-form';
import TagManager from 'react-gtm-module'
import DynamicForm, {uiStates} from '../components/DynamicForm';
import {extractToObject, extraneousValuifyObject} from '../utils';
import {steps as stepConstants} from '../constants';
import {steps as actions, api as apiActions} from '../actions';
import ErrorCard from '../components/ErrorCard';

const FORM_NAME = 'fundingRequirements';

export class FundingRequirements extends Component {
  state = {uiState: uiStates.IDLE};

  displayName = 'FundingRequirements';

  static propTypes = {
    aboutBusinessData: PropTypes.object,
    applicationId: PropTypes.string,
    clearErrors: PropTypes.func,
    error: PropTypes.object,
    fields: PropTypes.array.isRequired,
    isBusy: PropTypes.bool,
    postApi1: PropTypes.func.isRequired,
    pushLocation: PropTypes.func.isRequired,
    setStepData: PropTypes.func.isRequired,
    setStepResult: PropTypes.func.isRequired,
    step: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const {setActiveStep, step} = this.props;

    setActiveStep(step.id);
  }

  handleSubmit = values => {
    const {
      aboutBusinessData,
      applicationId,
      postApi1,
      pushLocation,
      setStepData,
      setStepResult,
      step,
    } = this.props;
    const currentStepId = step.id;

    setStepData(currentStepId, values);

    this.setState({uiState: uiStates.SUBMITTING});

    TagManager.initialize({gtmId: 'GTM-K6WSZMP',dataLayer: {event: 'fundingHubStep2'}})
    
    const payload = extraneousValuifyObject({
      ...aboutBusinessData,
      ...values,
      Id: applicationId,
      Step: currentStepId + 1,
    });

    return postApi1(payload)
      .then(result => {
        if (result.result === 'OK') {
          setStepResult(currentStepId, result);
          pushLocation(stepConstants.findById(currentStepId + 1).route);
        } else {
          this.setState({uiState: uiStates.IDLE});

          this.handleError(result);
        }
      })
      .catch(err => {
        this.setState({uiState: uiStates.IDLE});

        this.handleError(err);
      });
  };

  handleError = err => {
    setTimeout(() => this.scrollToElem(), 0);

    throw new SubmissionError({_error: err});
  };

  scrollToElem = () => {
    if (this.scrollElem) {
      window.scrollTo({top: this.scrollElem.offsetTop, left: 0, behaviour: 'smooth'});
    }
  };

  handleBackClick = () => {
    const {step, pushLocation} = this.props;

    pushLocation(stepConstants.findById(step.id - 1).route);
  };

  render() {
    const {uiState} = this.state;
    const {fields, initialValues} = this.props;

    return (
      <div ref={c => (this.scrollElem = c)}>
        <DynamicForm
          uiState={uiState}
          fields={fields}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          renderHeader={({error, clearErrors}) =>
            error ? <ErrorCard error={error} onCloseClick={clearErrors} /> : null
          }
          renderFooter={() => (
            <div>
              <Button variant="contained" disabled={uiState === uiStates.SUBMITTING} type="submit">
                CONTINUE
              </Button>

              <Button variant="text" onClick={this.handleBackClick}>
                BACK
              </Button>
            </div>
          )}
        />
      </div>
    );
  }
}

function mapStateToProps({application}) {
  const {steps, api} = application;
  const {results} = steps;
  const {applicationId} = results;
  const stepResults = results.fundingRequirements;
  const fields = stepResults ? stepResults.fields : [];
  const fieldDefaults = extractToObject('name', 'defaultValue', fields || []);
  const stepData = steps.data.fundingRequirements;

  return {
    initialValues: {...fieldDefaults, ...stepData},
    fields,
    isBusy: api.one.isBusy,
    applicationId,
    aboutBusinessData: application.steps.data.aboutBusiness,
  };
}

export default connect(mapStateToProps, {
  pushLocation: push,
  clearErrors: () => stopSubmit(FORM_NAME, {}),
  ...actions,
  ...apiActions,
})(FundingRequirements);

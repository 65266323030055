/* eslint-disable max-len */
const productOffering = {
  name: 'ProductOffering',
  label: 'What is the finance for?',
  type: 'ListSelect',
  required: true,
  help: 'What is the main purpose of the funding',
  options: [
    { value: '1', text: 'Acquiring/Expanding a Business' },
    { value: '2', text: 'Asset Rental' },
    { value: '3', text: 'Asset Purchase' },
    { value: '4', text: 'Day to day working capital' },
    { value: '5', text: 'Purchase Order' },
    { value: '6', text: 'Purchase Property' },
    { value: '7', text: 'Refinancing debt' },
  ],
};

const amtFundingRequired = {
  name: 'DealSize',
  label: 'How much funding do you need?',
  type: 'ListSelect',
  required: true,
  options: [
    { value: '1', text: 'R 0 - R 250,000' },
    { value: '2', text: 'R 250,000 - R 500,000' },
    { value: '3', text: 'R 500,000 - R 1mil' },
    { value: '4', text: 'R 1 mil plus' },
  ],
};

const fundingTerm = {
  name: 'FundingTerm',
  label: 'How long do you need the finance for?',
  type: 'ListSelect',
  required: true,
  options: [
    { value: '1', text: 'a few weeks' },
    { value: '2', text: 'a few months' },
    { value: '3', text: 'a few years' },
    { value: '4', text: 'more than 5 years' },
  ],
  help: 'An estimate of the amount of time you need the funding for',
};

const dealTurnaround = {
  name: 'DealTurnaround',
  label: 'How quickly do you need the finance?',
  type: 'ListSelect',
  required: true,
  options: [
    { value: '1', text: 'a few hours' },
    { value: '2', text: 'a few days' },
    { value: '3', text: 'a few weeks' },
  ],
  help: 'Estimate the timeframe required until payout of the funding',
};

const businessPlanForecast = {
  name: 'BusPlan',
  label: 'Do you have a business plan forecast?',
  type: 'RadioGroup',
  required: true,
  help: 'Many lenders require a business plan or a forecast to provide you with finance',
  options: [
    { value: '1', text: 'Yes' },
    { value: '2', text: 'No' },
  ],
};

const managementAccounts = {
  name: 'ManAccounts',
  label: 'Can you easily provide up to date management accounts?',
  type: 'RadioGroup',
  required: true,
  help: 'Up to date financial information is important to our lenders',
  options: [
    { value: '1', text: 'Yes' },
    { value: '2', text: 'No' },
  ],
};

const signedStatement = {
  name: 'FinStatements',
  label: 'Can you easily provide Signed Annual Financial Statements?',
  type: 'RadioGroup',
  required: true,
  help: 'Annual Financials are important to our lenders',
  options: [
    { value: '1', text: 'Yes' },
    { value: '2', text: 'No' },
  ],
};

const bankStatments= {
  name: 'BankStatements',
  label: 'Can you easily provide 3-6 Months Bank Statements?',
  type: 'RadioGroup',
  required: true,
  help: 'Bank Statements are often required by our lenders',
  options: [
    { value: '1', text: 'Yes' },
    { value: '2', text: 'No' },
  ],
};

export default [
  productOffering,
  dealTurnaround,
  amtFundingRequired,
  fundingTerm,
  businessPlanForecast,
  managementAccounts,
  signedStatement,
  bankStatments,
];
/* eslint-enable max-len */

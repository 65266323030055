import 'babel-polyfill';
import React from 'react';
import {render} from 'react-dom';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import LuxonUtils from 'material-ui-pickers/utils/luxon-utils';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';

import App from './App';
import theme from './theme';

render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.querySelector('.js-mount-point'),
);

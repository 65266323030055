import React from 'react';
import MaterialTextField from '@material-ui/core/TextField';
import wrapField from './Field';

/* eslint-disable-next-line */
const TextField = ({visible, ...rest}) => <MaterialTextField {...rest} />;

TextField.displayName = 'TextField';

export {TextField};

export default wrapField(TextField);

/* eslint-disable max-len */
const name = {
  name: 'FirstName',
  label: 'Name',
  type: 'Text',
  required: true,
};

const surname = {
  name: 'Surname',
  label: 'Surname',
  type: 'Text',
  required: true,
};

const email = {
  name: 'Email',
  label: 'Email',
  type: 'Text',
  required: true,
  isEmail: true,
};

const contactNumber = {
  name: 'Contact',
  label: 'Contact Number',
  type: 'Text',
  required: true,
};

const altcontactNumber = {
  name: 'AlternateContact',
  label: 'Alternate Contact Number',
  type: 'Text',
  required: true,
};

const region = {
  name: 'Region',
  label: 'Province',
  type: 'ListSelect',
  required: true,
  options: [
    { value: '1', text: 'Eastern Cape' },
    { value: '2', text: 'Free State' },
    { value: '3', text: 'Gauteng' },
    { value: '4', text: 'Kwazulu Natal' },
    { value: '5', text: 'Limpopo' },
    { value: '6', text: 'North West' },
    { value: '7', text: 'Northern Cape' },
    { value: '8', text: 'Mpumalanga' },
    { value: '9', text: 'Western Cape' },
  ],
};
const suburb = {
  name: 'Suburb',
  label: 'Suburb',
  type: 'Text',
  required: true,
};

export default [
  name,
  surname,
  email,
  contactNumber,
  altcontactNumber,
  suburb,
  region,
];
/* eslint-enable max-len */

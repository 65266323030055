import omit from 'lodash/fp/omit';
import compose from 'lodash/fp/compose';
import extend from 'lodash/fp/extend';
import assign from 'object.assign';

export const CALL_API = 'CALL_API';

const { encodeURIComponent } = global;

export function apiAction(def, extra) {
  return {
    ...extra,
    [CALL_API]: def,
  };
}

const defaultOptions = {
  baseUrl: false,
};

function pathJoin(...paths) {
  return paths
    .map((path, i) => {
      let p = path;
      if (i > 0 && p[0] === '/') {
        p = p.slice(1);
      }
      if (p[p.length - 1] === '/') {
        p = p.slice(0, p.length - 1);
      }
      return p;
    }).join('/');
}

function getQueryString(query) {
  return Object.keys(query)
    .reduce((acc, k) => acc.concat(`${k}=${encodeURIComponent(query[k])}`), [])
    .join('&');
}

export default function createApiMiddleware(provider, opts) {
  const options = assign({}, defaultOptions, opts);

  function getUrl(url, query) {
    let result;
    if (options.baseUrl) {
      result = url ? pathJoin(options.baseUrl, url) : options.baseUrl;
    } else {
      result = url;
    }

    if (!query) {
      return result;
    }

    const queryStr = getQueryString(query);
    return `${result}?${queryStr}`;
  }

  return () => (next) => (action) => {
    const apiCallAction = action[CALL_API];
    if (!apiCallAction) {
      return next(action);
    }

    const { types, url, method, body, query, headers = {} } = apiCallAction;

    const [requestType, successType, failureType] = types;

    const actionWith = compose(omit(CALL_API), extend(action));

    next(actionWith({ type: requestType }));
    return provider(getUrl(url, query), {
      method,
      body: encodeURIComponent(JSON.stringify(body)),
      headers,
    })
      .then(r => r.json())
      /* eslint no-shadow: 0 */
      .then((data) => {
        next(actionWith({
          type: successType,
          body: data,
          status: 200,
        }));

        return data;
      })
      .catch((err) => {
        next(actionWith({ type: failureType, error: err }));
        return Promise.reject(err);
      });
  };
}

import React from 'react';
import {connect} from 'react-redux';
import {reduxForm as connectForm, stopSubmit} from 'redux-form';

import Loader from '../components/Loader';
import DynamicFields from '../components/fields/Dynamic';
import {validateFields} from '../utils';

const FORM_NAME = 'dynamicForm';

export const uiStates = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUBMITTING: 'submitting',
};

export class DynamicForm extends React.Component {
  displayName = 'DynamicForm';

  componentDidMount() {
    this.props.initialize(this.props.initialValues);
  }

  render() {
    const {
      fields,
      formValues,
      handleSubmit,
      onSubmit,
      renderError,
      renderFooter,
      renderHeader,
      uiState,
    } = this.props;

    return uiState === uiStates.LOADING ? (
      <Loader>Please wait a moment...</Loader>
    ) : (
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderHeader ? renderHeader(this.props) : null}

        {uiState === uiStates.IDLE || uiState === uiStates.SUBMITTING ? (
          <DynamicFields fields={fields} formValues={formValues} />
        ) : null}

        {renderError ? renderError(this.props) : null}

        {renderFooter ? renderFooter(this.props) : null}
      </form>
    );
  }
}

const mapStateToProps = ({form}, {initialValues}) => {
  const formObj = form[FORM_NAME];
  const userFormValues = formObj ? formObj.values : {};
  const formValues = {...initialValues, ...userFormValues};

  return {formValues};
};

export default connect(mapStateToProps, {
  clearErrors: () => stopSubmit(FORM_NAME, {}),
})(
  connectForm({
    enableReinitialize: true,
    form: FORM_NAME,
    validate: validateFields(),
  })(DynamicForm),
);

import React from 'react';
import {connect} from 'react-redux';

import {setActiveStep} from '../actions/steps';

class Success extends React.Component {
  componentDidMount() {
    const {setActiveStep} = this.props;

    setActiveStep();
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = {
  setActiveStep,
};

export default connect(null, mapDispatchToProps)(Success);

import {composeValidators, combineValidators} from 'redux-form-revalid';
import isRequired from 'redux-form-revalid/validations/isRequired';
import isEmail from 'redux-form-revalid/validations/isEmail';
import {minLength, maxLength} from 'revalid';
import assign from 'object.assign';

import evaluateVisible from './evaluateVisible';

function convertToValidator(field, fields, values) {
  const validators = [];

  const isVisible = evaluateVisible(field, fields, values);

  if (!isVisible) {
    return () => false;
  }

  if (field.optional === false || field.required === true || field.required === 'true') {
    validators.push(isRequired());
  }

  if (field.isEmail) {
    validators.push(isEmail());
  }

  if (field.maxLength !== undefined) {
    validators.push(maxLength(field.maxLength));
  }

  if (field.minLength !== undefined) {
    validators.push(minLength(field.minLength));
  }

  if (field.custom) {
    validators.push(field.custom);
  }

  if (validators.length === 0) {
    return () => false;
  }

  if (validators.length === 1) {
    return validators[0];
  }

  return composeValidators(...validators);
}

function createValidator(fields, values) {
  const validators = fields.reduce(
    (obj, field) => assign(obj, {[field.name]: convertToValidator(field, fields, values)}),
    {},
  );

  return combineValidators(validators);
}

export default function validateFields(options = {}) {
  return (values, props) => {
    const validator = createValidator(
      options.fields || props[options.fieldPropName || 'fields'],
      values,
    );
    return validator(values);
  };
}

import React from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import Button from '@material-ui/core/Button';
import {SubmissionError} from 'redux-form';
import DynamicForm, {uiStates} from '../components/DynamicForm';
import ErrorCard from '../components/ErrorCard';
import {extraneousValuifyObject, extractToObject} from '../utils';
import {steps as stepConstants} from '../constants';
import {steps as actions, api as apiActions} from '../actions';
import TagManager from 'react-gtm-module'
export class AboutBusiness extends React.Component {
  displayName = 'AboutBusiness';

  state = {error: null, uiState: uiStates.LOADING};

  componentDidMount() {
    const {setActiveStep, step, getApiAboutBusiness, applicationId, setStepResult} = this.props;

    const currentStepId = step.id;
    const payload = applicationId
      ? {Step: currentStepId, Id: applicationId}
      : {Step: currentStepId};

    setActiveStep(step.id);

    getApiAboutBusiness(extraneousValuifyObject(payload))
      .then(result => {
        if (result.result === 'OK') {
          setStepResult(currentStepId, {...result, id: result.id || applicationId});
        } else {
          this.setState({error: new Error(result.message || 'Unknown error')});
        }
      })
      .catch(error => {
        this.setState({error});
      });
  }

  componentDidUpdate(prevProps) {
    const {isBusy: prevIsBusy} = prevProps;
    const {isBusy} = this.props;

    if (!prevIsBusy && isBusy) {
      /* eslint-disable-next-line */
      this.setState({uiState: uiStates.LOADING});
    }

    if (prevIsBusy && !isBusy) {
      /* eslint-disable-next-line */
      this.setState({uiState: uiStates.IDLE});
    }
  }

  handleSubmit = values => {
    const {applicationId, pushLocation, postApi1, step, setStepData, setStepResult} = this.props;

    const currentStepId = step.id;
    this.setState({uiState: uiStates.SUBMITTING});

    TagManager.initialize({gtmId: 'GTM-K6WSZMP',dataLayer: {event: 'fundingHubStep1'}})
    
    let qresult = '';
    qresult = location.search;
    qresult = qresult.replace(/=/g, '~');
    qresult = qresult.replace(/&/g, '!#!#');
    setStepData(currentStepId, {Qval: qresult, Id: applicationId, ...values});

    const payload = extraneousValuifyObject({
      ...values,
      Id: applicationId,
      Qval: qresult,
      Step: currentStepId + 1,
    });

    return postApi1(payload)
      .then(result => {
        if (result.result === 'OK') {
          setStepResult(currentStepId, result);

          pushLocation(stepConstants.findById(currentStepId + 1).route);
        } else {
          this.setState({uiState: uiStates.IDLE});

          throw new SubmissionError({_error: result});
        }
      })
      .catch(err => {
        this.setState({uiState: uiStates.IDLE});

        throw new SubmissionError({_error: err});
      });
  };

  render() {
    const {uiState, error: initialError} = this.state;
    const {fields, initialValues} = this.props;

    return initialError ? (
      <ErrorCard error={initialError} />
    ) : (
      <DynamicForm
        fields={fields}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        uiState={uiState}
        renderHeader={({error, clearErrors}) =>
          error ? <ErrorCard error={error} onCloseClick={clearErrors} /> : null
        }
        renderFooter={() => (
          <Button variant="contained" disabled={uiState === uiStates.SUBMITTING} type="submit">
            CONTINUE
          </Button>
        )}
      />
    );
  }
}

function mapStateToProps({application}) {
  const {api, steps} = application;
  const {results} = steps;
  const {applicationId} = results;
  const stepResults = results.aboutBusiness;
  const fields = stepResults ? stepResults.fields : [];
  const fieldDefaults = extractToObject('name', 'defaultValue', fields || []);

  return {
    applicationId,
    fields,
    initialValues: {...fieldDefaults, ...steps.data.aboutBusiness},
    isBusy: api.aboutBusiness.isBusy,
  };
}

export default connect(mapStateToProps, {
  pushLocation: push,
  ...actions,
  ...apiActions,
})(AboutBusiness);

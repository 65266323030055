import {apiAction} from '../store/middleware/api';

export const API_ABOUTBUSINESS_GET_REQUEST = '@api/ABOUTBUSINESS_GET_REQUEST';
export const API_ABOUTBUSINESS_GET_SUCCESS = '@api/ABOUTBUSINESS_GET_REQUEST_SUCCESS';
export const API_ABOUTBUSINESS_GET_FAIL = '@api/ABOUTBUSINESS_GET_FAIL';

export function getApiAboutBusiness(body) {
  return dispatch =>
    dispatch(
      apiAction({
        types: [
          API_ABOUTBUSINESS_GET_REQUEST,
          API_ABOUTBUSINESS_GET_SUCCESS,
          API_ABOUTBUSINESS_GET_FAIL,
        ],
        query: {routine: 'SNPTFQSTEA', XFHKey: 'XFH'},
        method: 'POST',
        body,
      }),
    );
}

export const API1_POST_REQUEST = '@api1/POST_REQUEST';
export const API1_POST_SUCCESS = '@api1/POST_REQUEST_SUCCESS';
export const API1_POST_FAIL = '@api1/POST_FAIL';

export function postApi1(body) {
  return dispatch =>
    dispatch(
      apiAction({
        types: [API1_POST_REQUEST, API1_POST_SUCCESS, API1_POST_FAIL],
        query: {routine: 'SNPTFQSTEA', XFHKey: 'XFH'},
        method: 'POST',
        body,
      }),
    );
}

export const API2_POST_REQUEST = '@api2/POST_REQUEST';
export const API2_POST_SUCCESS = '@api2/POST_REQUEST_SUCCESS';
export const API2_POST_FAIL = '@api2/POST_FAIL';

export function postApi2(body) {
  return dispatch =>
    dispatch(
      apiAction({
        types: [API2_POST_REQUEST, API2_POST_SUCCESS, API2_POST_FAIL],
        query: {routine: 'SNPTFQSTEA', XFHKey: 'XFH'},
        method: 'POST',
        body,
      }),
    );
}

export const API3_POST_REQUEST = '@api3/POST_REQUEST';
export const API3_POST_SUCCESS = '@api3/POST_REQUEST_SUCCESS';
export const API3_POST_FAIL = '@api3/POST_FAIL';

export function postApi3(body) {
  return dispatch =>
    dispatch(
      apiAction({
        types: [API3_POST_REQUEST, API3_POST_SUCCESS, API3_POST_FAIL],
        query: {routine: 'SNPTFQSTEA', XFHKey: 'XFH'},
        method: 'POST',
        body,
      }),
    );
}

import {createMuiTheme} from '@material-ui/core/styles';
import {redA100} from '@material-ui/core/colors';

const blue = '#00bcd4';
const green = '#66cc99';
const grey = '#cacaca';
const greenNeon = '$4ad6ae';

const theme = createMuiTheme({
  palette: {
    primary: {main: green, contrastText: 'white'},
    secondary: {main: blue},
  },

  error: redA100,

  overrides: {
    MuiButton: {
      root: {
        backgroundColor: green,
        // color: 'white',
      },
      contained: {
        backgroundColor: green,
        color: 'white',
      },
      text: {
        backgroundColor: 'white',
        color: 'black',
      },
    },

    MuiCardHeader: {
      title: {
        color: 'white',
      },
    },

    MuiStepIcon: {
      root: {
        color: grey,
        '&$active': {
          color: blue,
        },
        '&$completed': {
          color: blue,
        },
      },
    },
  },

  typography: {
    useNextVariants: true,
  },
});

export {green, greenNeon, grey};

export default theme;

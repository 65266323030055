import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import Button from '@material-ui/core/Button';
import {SubmissionError} from 'redux-form';
import assign from 'object.assign';
import TagManager from 'react-gtm-module'
import DynamicForm, {uiStates} from '../components/DynamicForm';
import {extractToObject, extraneousValuifyObject} from '../utils';
import {steps as stepConstants,analytics} from '../constants';
import {steps as actions, api as apiActions} from '../actions';
import ErrorCard from '../components/ErrorCard';

export class Trade extends Component {
  displayName = 'Trade';

  state = {uiState: uiStates.IDLE};

  scrollElem = null;

  static propTypes = {
    setStepData: PropTypes.func.isRequired,
    setStepResult: PropTypes.func.isRequired,
    pushLocation: PropTypes.func.isRequired,
    postApi2: PropTypes.func.isRequired,
    step: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    applicationId: PropTypes.string,
    isBusy: PropTypes.bool,
    error: PropTypes.object,
    clearErrors: PropTypes.func,
  };

  componentDidMount() {
    const {setActiveStep, step} = this.props;

    setActiveStep(step.id);
  }

  handleSubmit = values => {
    const {pushLocation, setStepData, step, postApi2, setStepResult, applicationId} = this.props;

    const currentStepId = step.id;
    setStepData(currentStepId, values);

    const payload = extraneousValuifyObject(
      assign({}, values, {
        Id: applicationId,
        Step: currentStepId + 1,
      }),
    );

    TagManager.initialize({gtmId: analytics.googletag,dataLayer: {event: 'fundingHubStep3'}})
    
    return postApi2(payload)
      .then(result => {
        if (result.result === 'OK') {
          setStepResult(currentStepId, result);
          pushLocation(stepConstants.findById(currentStepId + 1).route);
        } else {
          this.handleError(result);
        }
      })
      .catch(err => {
        this.handleError(err);
      });
  };

  handleError = err => {
    setTimeout(() => this.scrollToElem(), 0);

    throw new SubmissionError({_error: err});
  };

  scrollToElem = () => {
    if (this.scrollElem) {
      window.scrollTo({top: this.scrollElem.offsetTop, left: 0, behaviour: 'smooth'});
    }
  };

  handleBackClick = () => {
    const {step, pushLocation} = this.props;

    pushLocation(stepConstants.findById(step.id - 1).route);
  };

  render() {
    const {uiState} = this.state;
    const {fields, initialValues} = this.props;

    return (
      <div ref={c => (this.scrollElem = c)}>
        <DynamicForm
          uiState={uiState}
          fields={fields}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          renderHeader={({error, clearErrors}) =>
            error ? <ErrorCard error={error} onCloseClick={clearErrors} /> : null
          }
          renderFooter={() => (
            <div>
              <Button disabled={uiState === uiStates.SUBMITTING} type="submit" variant="contained">
                CONTINUE
              </Button>

              <Button variant="text" onClick={this.handleBackClick}>
                BACK
              </Button>
            </div>
          )}
        />
      </div>
    );
  }
}

function mapStateToProps({application}) {
  const {steps, api} = application;
  const {results} = steps;
  const {applicationId} = results;
  const stepResponse = results.trade;
  const fields = stepResponse ? stepResponse.fields : [];
  const stepData = steps.data.trade;
  const fieldDefaults = extractToObject('name', 'defaultValue', fields);

  return {
    initialValues: assign(fieldDefaults, stepData),
    fields,
    applicationId,
    isBusy: api.two.isBusy,
  };
}

export default connect(mapStateToProps, {
  pushLocation: push,
  ...actions,
  ...apiActions,
})(Trade);

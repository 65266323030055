import assign from 'object.assign';

export default function extraneousValuifyObject(values, opts) {
  const options = assign({ except: [] }, opts);
  return Object.keys(values)
    .reduce((acc, key) => {
      const value = values[key];
      if (value === null || value === undefined) { return acc; }
      if (options.except.indexOf(key) > -1) {
        return assign(acc, { [key]: value });
      }
      return assign(acc, { [key]: { value } });
    }, {});
}

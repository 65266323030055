import {combineReducers} from 'redux';
import {steps as stepActions, api as apiActions} from '../actions';
import {steps} from '../constants';

const initialStates = {
  api: {isBusy: false},
  steps: {activeStep: steps[0], data: {}, results: {}},
};

function apiReducer(actions) {
  const [REQUEST, SUCCESS, FAIL] = actions;
  return (state = initialStates.api, action) => {
    switch (action.type) {
      case REQUEST:
        return {isBusy: true};
      case SUCCESS:
      case FAIL:
        return {isBusy: false};

      default:
        return state;
    }
  };
}

function stepsReducer(state = initialStates.steps, action) {
  let results;
  const actionStep = action.id ? steps.findById(action.id) : null;

  switch (action.type) {
    case stepActions.STEPS_SET_ACTIVE: {
      const activeStep = action.stepId ? steps.findById(action.stepId) : {};

      return {...state, activeStep};
    }

    case stepActions.STEPS_SET_STEP_DATA: {
      const data = {...state.data, [actionStep.name]: action.data};

      return {...state, data};
    }

    case stepActions.STEPS_SET_STEP_RESULT: {
      const {result} = action;
      // funders response is inconsistent with other responses, so we have to
      // handle it specifically
      const {id: applicationId, funders, nextStep} = result;
      const nextActionStep = nextStep ? steps.findById(Number(nextStep.step)) : null;
      const nextActionStepObj = nextActionStep
        ? {[nextActionStep.name]: {...nextActionStep, ...nextStep}}
        : null;
      const fundersActionStep = funders ? steps.findByName('funder') : null;
      const fundersActionStepObj = fundersActionStep
        ? {[fundersActionStep.name]: {funders, fundersActionStep}}
        : null;
      results = {
        ...state.results,
        applicationId:
          state.results && state.results.applicationId
            ? state.results.applicationId
            : applicationId,
        ...nextActionStepObj,
        ...fundersActionStepObj,
      };

      return {...state, results};
    }

    case stepActions.STEPS_RESET:
      return initialStates.steps;

    default:
      return state;
  }
}

const reducer = combineReducers({
  api: combineReducers({
    aboutBusiness: apiReducer([
      apiActions.API_ABOUTBUSINESS_GET_REQUEST,
      apiActions.API_ABOUTBUSINESS_GET_SUCCESS,
      apiActions.API_ABOUTBUSINESS_GET_FAIL,
    ]),
    one: apiReducer([
      apiActions.API1_POST_REQUEST,
      apiActions.API1_POST_SUCCESS,
      apiActions.API1_POST_FAIL,
    ]),
    two: apiReducer([
      apiActions.API2_POST_REQUEST,
      apiActions.API2_POST_SUCCESS,
      apiActions.API2_POST_FAIL,
    ]),
    three: apiReducer([
      apiActions.API3_POST_REQUEST,
      apiActions.API3_POST_SUCCESS,
      apiActions.API3_POST_FAIL,
    ]),
  }),
  steps: stepsReducer,
});

export default function applicationReducer(state = {}, action) {
  return reducer(state, action);
}

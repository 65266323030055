import React from 'react';
import NumberFormat from 'react-number-format';

import {TextField} from './TextField';

import wrapField from './Field';

const CustomNumberFormat = props => {
  /* eslint-disable-next-line */
  const {inputRef, onChange, value: inputValue, ...rest} = props;
  const value = inputValue.target ? inputValue.target.value : inputValue;

  return (
    <NumberFormat
      {...rest}
      decimalScale={2}
      fixedDecimalScale={true}
      getInputRef={inputRef}
      prefix="R "
      thousandSeparator
      value={value}
    />
  );
};

class CurrencyField extends React.Component {
  static displayName = 'CurrencyField';

  render() {
    return <TextField {...this.props} InputProps={{inputComponent: CustomNumberFormat}} />;
  }
}

export default wrapField(CurrencyField);

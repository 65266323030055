/* eslint-disable max-len */
//const companyName = {
//name: 'registeredCompanyName',
//label: 'What is your registered company name?',
//type: 'Text',
//required: true,
//};

const companyLegalStatus = {
  name: 'LegalStatus',
  label: 'What is the legal status of your company?',
  type: 'ListSelect',
  required: true,
  help: 'What type of company is it registered as',
  options: [
    {value: '1', text: 'Branch of a foreign company'},
    {value: '2', text: 'Business Trust'},
    {value: '3', text: 'Close Corporation'},
    {value: '4', text: 'Partnership'},
    {value: '5', text: 'Personal Liability Company Inc'},
    {value: '6', text: 'Private Company (Pty) Ltd.'},
    {value: '7', text: 'Public Company (Ltd.)'},
    {value: '8', text: 'Sole Proprietor'},
    {value: '9', text: 'State Owned Company - SOC'},
  ],
};

const companyTradingRecord = {
  name: 'TradingRecord',
  label: 'How long have you been trading for?',
  type: 'ListSelect',
  required: true,
  help: 'How long have you been in operation since your first day of trading',
  options: [
    {value: '1', text: "Haven't started trading"},
    {value: '2', text: 'Revenues for 6 months or less'},
    {value: '3', text: 'Revenues for 6-12 months'},
    {value: '4', text: 'Revenues for 1-2 years'},
    {value: '5', text: 'Revenues for 2-3 years'},
    {value: '6', text: 'Revenues for 3-5 Years'},
    {value: '7', text: 'Revenues for 5-10 years'},
    {value: '8', text: 'Revenues for 10 years plus'},
  ],
  condition: "LegalStatus.value == '1' or LegalStatus.value == '3'",
};

const companyTurnover = {
  name: 'Turnover',
  label: 'What is your typical annual turnover?',
  type: 'ListSelect',
  required: true,
  help: 'What is the total value of income generated in the last 12 months',
  options: [
    {value: '1', text: '0 - R 1,000,000'},
    {value: '2', text: 'R 1,000,000 - R 5,000,000'},
    {value: '3', text: 'R 5,000,000 - R 10,000,000'},
    {value: '4', text: 'R 10,000,000 - R 30,000,000'},
    {value: '5', text: 'R 30,000,000 - R 60,000,000'},
    {value: '6', text: 'R 60,000,000 and above'},
  ],
};

const companyProfitability = {
  name: 'Profitability',
  label: 'How profitable is your business?',
  type: 'ListSelect',
  required: true,
  help: 'What is your profit/loss after all costs',
  options: [
    {value: '1', text: 'Currently loss making'},
    {value: '2', text: 'Yet to make first profit'},
    {value: '3', text: 'Approaching profitability'},
    {value: '4', text: 'Slightly profitable'},
    {value: '5', text: 'Comfortably profitable'},
  ],
};

const companySector = {
  name: 'Sector',
  label: 'What best describes your sector?',
  type: 'ListSelect',
  required: true,
  help: 'Type of industry that you trade in',
  options: [
    {value: '1', text: 'Agriculture, mining and resources'},
    {value: '2', text: 'Business services'},
    {value: '3', text: 'Charities and social enterprises'},
    {value: '4', text: 'Construction'},
    {value: '5', text: 'Electricity, gas and water supply'},
    {value: '6', text: 'Financial services'},
    {value: '7', text: 'Health, education and social work'},
    {value: '8', text: 'Hotels and restaurants'},
    {value: '9', text: 'Manufacturing'},
    {value: '10', text: 'Professional services'},
    {value: '11', text: 'Real estate and renting'},
    {value: '12', text: 'Technology and communications'},
    {value: '13', text: 'Transport and storage'},
    {value: '14', text: 'Wholesale and retail trade'},
    {value: '15', text: 'Other'},
  ],
};

//const hasJudgements = {
//name: 'Lst_AdverseRecords',
//required: true,
//label:
//'Are you aware of any judgements/defaults against ' +
//'your business or any of its directors or shareholders?',
//type: 'RadioGroup',
//options: [{value: '1', text: 'Yes'}, {value: '2', text: 'No'}],
//};

export default [
  // companyName,
  companyLegalStatus,
  companyTradingRecord,
  companyTurnover,
  companyProfitability,
  companySector,
  // hasJudgements,
];
/* eslint-enable max-len */

import React from 'react';
import {connect} from 'react-redux';

import HeaderSuccess from './HeaderSuccess';
import Logo from './Logo';
import PrimaryMenu from './PrimaryMenu';
import FundingSteps from '../../components/FundingSteps';
import {headings} from '../../constants';

import routes from '../../routes';

class Header extends React.Component {
  static displayName = 'Header';

  render() {
    const {activeStep = {}, location} = this.props;
    const heading = headings[activeStep.name] || {};
    const isSuccessRoute = location.pathname === routes.success;
    const areaHeaderClass = activeStep.name ? `area-header__${activeStep.name}` : '';
    const successHeaderClass = isSuccessRoute ? 'area-header__success' : '';

    return (
      <div className={areaHeaderClass || successHeaderClass}>
        <div className="wrap--large--hard-sides row">
          <div className="small-4 columns">
            <Logo />
          </div>
          <div className="columns">
            <div className="text--right">
              <PrimaryMenu />
            </div>
          </div>
        </div>

        {location.pathname === routes.success ? (
          <HeaderSuccess />
        ) : (
          <div>
            <div className="wrap--small">
              <div className="header-text">
                <h1>{heading.title}</h1>
                <p>{heading.subtitle}</p>
              </div>
            </div>

            <div className="wrap bgc--white">
              <FundingSteps stepIndex={activeStep.id - 1} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({application}) => {
  return {activeStep: application.steps.activeStep};
};

export default connect(mapStateToProps)(Header);

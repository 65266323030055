import PropTypes from 'prop-types';
import React from 'react';
import {Field} from 'redux-form';

import CheckField from '../../components/fields/CheckField';
import CurrencyField from '../../components/fields/CurrencyField';
import DateField from '../../components/fields/DateField';
import RadioGroup from '../../components/fields/RadioGroup';
import SelectField from '../../components/fields/SelectField';
import TextField from '../../components/fields/TextField';
import TickBox from '../../components/fields/TickBox';
import evaluateVisible from '../../utils/evaluateVisible';

function getComponent({type}) {
  switch (type.toLowerCase()) {
    case 'radiogroup':
      return RadioGroup;

    case 'listselect':
    case 'select':
      return SelectField;

    case 'checkbox':
      return CheckField;

    case 'tickbox':
      return TickBox;

    case 'currency':
      return CurrencyField;

    case 'date':
      return DateField;

    default:
      return TextField;
  }
}

const convertToFieldProp = (fields, values) => field => {
  const visible = evaluateVisible(field, fields, values);

  switch (field.type.toLowerCase()) {
    case 'checkbox':
    case 'tickbox':
      return {
        component: getComponent(field),
        fullWidth: true,
        label: field.label,
        name: field.name,
        options: field.options,
        title: field.help,
        visible,
      };

    case 'radiogroup':
      return {
        component: getComponent(field),
        fullWidth: true,
        label: field.label,
        name: field.name,
        options: field.options,
        title: field.help,
        visible,
      };

    default:
      return {
        component: getComponent(field),
        label: field.label,
        fullWidth: true,
        name: field.name,
        options: field.options,
        title: field.help,
        visible,
      };
  }
};

const DynamicFields = ({fields, formValues}) => {
  const fieldProps = fields.map(convertToFieldProp(fields, formValues));

  return <div>{fieldProps.map((fp, i) => <Field key={`${fp.name}-${i}`} {...fp} />)}</div>;
};

DynamicFields.displayName = 'DynamicFields';
DynamicFields.shouldComponentUpdate = () => false;
DynamicFields.propTypes = {
  fields: PropTypes.array.isRequired,
};

export default DynamicFields;

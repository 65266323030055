import PropTypes from 'prop-types';
import React from 'react';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import wrapField from './Field';

const CheckField = ({label, onChange, value, ...rest}) => (
  <FormControlLabel
    control={<MaterialCheckbox {...rest} onChange={onChange} checked={value} />}
    label={label}
  />
);

CheckField.displayName = 'CheckField';
CheckField.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  value: PropTypes.any,
};

export default wrapField(CheckField);

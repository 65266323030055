import React from 'react';

const Logo = () => (
  <div>
    <a className="logo" href="https://www.fundinghub.co.za">
      <img src="/assets/img/logo.svg" alt="Funding Hub Logo" />
    </a>
  </div>
);

export default Logo;

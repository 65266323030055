import {createStore, compose, applyMiddleware} from 'redux';
import persistState, {mergePersistedState} from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/sessionStorage';
import filter from 'redux-localstorage-filter';
import fetch from 'isomorphic-fetch';
import reduxThunk from 'redux-thunk';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';

import appReducers from '../reducers';
import apiMiddleware from './middleware/api';

const {sessionStorage} = global;

const history = createBrowserHistory();

const persistentReducer = compose(mergePersistedState())(appReducers);

const persistedStorage = compose(filter('application.steps'))(adapter(sessionStorage));

const middlewares = [
  routerMiddleware(history),
  reduxThunk,
  /* eslint no-undef: 0 */
  apiMiddleware(fetch, {baseUrl: webpack.apiBaseUrl}),
];

const enhancer = compose(
  applyMiddleware(...middlewares),
  persistState(persistedStorage, 'persistence'),
  window.devToolsExtension ? window.devToolsExtension() : f => f,
);

const initialState = {};

export {history};

export default function createAppStore() {
  return createStore(connectRouter(history)(persistentReducer), initialState, enhancer);
}

import PropTypes from 'prop-types';
import React from 'react';

const Footer = ({ className }) => (
  <div className={className || 'area-footer'}>
    <div className="text-center">
      &copy; FundingHub {new Date().getFullYear()} |
      All rights reserved | <a href="https://fundinghub.co.za/terms">Terms and Conditions</a>
    </div>
  </div>
);

Footer.displayName = 'Footer';
Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;

export default {
  aboutBusiness: {
    title: 'Tell us about your business',
    subtitle: 'Tell us more about your business so that we can help get you the funds you need',
  },
  fundingRequirements: {
    title: 'Tell us about your funding requirements',
    subtitle: 'Tell us more about the funds you require',
  },
  trade: {
    title: 'Tell us how you trade',
    subtitle: 'How does your business tick?',
  },
  funder: {
    title: 'Make your choice',
    subtitle: 'Time to choose the perfect funder',
  },
};
